import { FC, ReactNode } from 'react';

import { useLocation } from 'react-router';

import { Routes } from '@api/tools';

import { Header, Menu } from '@components';
import useGlobalStatus from '@context/status-provider';
import { WithErrorAndLoader } from '@hocs/with-error-and-loader';

import styles from '@components/page/index.module.scss';

interface Props {
    error?: boolean;
    loading?: boolean;
    children?: ReactNode;
}

export const Page: FC<Props> = ({ children, error = false }) => {
    const { isLoading: loading } = useGlobalStatus();
    const location = useLocation();
    const isLoginPage = location.pathname === Routes.LOGIN;

    if (isLoginPage) {
        return <>{children}</>;
    }
    return (
        <div className={styles.container}>
            <Header />

            <div className={styles.wrapper}>
                <Menu />
                <div className={`${styles.content} content`}>
                    <WithErrorAndLoader
                        error={error}
                        errorProps={{
                            centerText: true,
                        }}
                        loading={loading}
                    >
                        {children}
                    </WithErrorAndLoader>
                </div>
            </div>
        </div>
    );
};

export default Page;
